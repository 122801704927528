<template>
	<div>

		<!-- filter -->
		<div class="row">
			<div class="col-md-12">
				<div class="card card-body">
					<div class="row">

						<!-- company id -->
						<div class="col-md-6 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-work_place_id">Empresa</label>
								<v-select :options="filterCompanies" label="corporate_name" v-bind:class="{'is-invalid': errors.company}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.company_id" @input="validateCompany('filter')"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.company" >
									<p>{{ errors.company[0] }}</p>
								</div>
							</div>
						</div>

						<!-- contract -->
						<div class="col-md-6 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-work_place_id">Contrato</label>
								<v-select :options="filterContracts" label="corporate_name" v-bind:class="{'is-invalid': errors.contract}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.contract_id"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.contract" >
									<p>{{ errors.contract[0] }}</p>
								</div>
							</div>
						</div>

						<!-- work_place_id -->
						<div class="col-md-6 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-work_place_id">Local de Trabalho</label>
								<v-select :options="filterWorkplaces" label="corporate_name" v-bind:class="{'is-invalid': errors.workplace}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.work_place_id" @input="validateWorkplace('filter')"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.workplace" >
									<p>{{ errors.workplace[0] }}</p>
								</div>
							</div>
						</div>

						<!-- sublocal -->
						<div class="col-md-6 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-sublocal">Sublocal</label>
								<v-select :options="filterSublocal" label="corporate_name" v-bind:class="{'is-invalid': errors.sublocal}" :reduce="corporate_name => corporate_name.id" v-model="filterItems.sublocal_id" @input="validateSublocal('filter')"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.sublocal" >
									<p>{{ errors.sublocal[0] }}</p>
								</div>
							</div>
						</div>

						<!-- movement id -->
						<div class="col-md-2 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-work_place_id">Nº da Movimentação</label>
								<input type="number" class="form-control" label="movement_id" v-bind:class="{'is-invalid': errors.movement_id}" v-model="filterItems.movement_id"/>
								<div class="invalid-feedback d-block" v-if="errors.movement_id" >
									<p>{{ errors.movement_id[0] }}</p>
								</div>
							</div>
						</div>

						<!-- item -->
						<div class="col-md-10 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-itemsSelected">Itens</label>
								<v-select :options="filterListItems" label="name" :reduce="name => name.item_id" v-bind:class="{'is-invalid': errors.itemsSelected}" v-model="filterItems.itemsSelected" multiple></v-select>
								<div class="invalid-feedback d-block" v-if="errors.itemsSelected">
									<p>{{ errors.itemsSelected[0] }}</p>
								</div>
							</div>
						</div>

            <!-- template -->
            <div class="col-md-8 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-itemsSelected">Template</label>
								<v-select :options="filterTemplates" label="name" :reduce="name => name.id" v-bind:class="{'is-invalid': errors.template_id}" v-model="filterItems.template_id"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.template_id">
									<p>{{ errors.template_id[0] }}</p>
								</div>
							</div>
						</div>

						<!-- type -->
						<div class="col-md-4 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-type">Tipo</label>
								<v-select :options="optionsType" label="label" v-bind:class="{'is-invalid': errors.type}" :reduce="label => label.value" v-model="filterItems.type"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.type">
									<p>{{ errors.type[0] }}</p>
								</div>
							</div>
						</div>
						
						<!-- sector_id -->
						<div class="col-md-3 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-sector_id">Setor</label>
								<v-select :options="filterSectors" label="name" v-bind:class="{'is-invalid': errors.sector_id}" :reduce="name => name.id" v-model="filterItems.sector_id" @input="loadItems()"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.sector_id">
									<p>{{ errors.sector_id[0] }}</p>
								</div>
							</div>
						</div>
						
						<!-- subsector_id -->
						<div class="col-md-3 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-subsector_id">Subsetor</label>
								<v-select :options="filterSubsectors" v-bind:class="{'is-invalid': errors.subsector_id}" :reduce="name => name.id" label="name" v-model="filterItems.subsector_id" @input="loadItems()"></v-select>
								<div class="invalid-feedback d-block" v-if="errors.subsector_id">
									<p>{{ errors.subsector_id[0] }}</p>
								</div>
							</div>
						</div>

						<!-- date -->
						<div class="col-md-3 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-start_date">Data Inicial</label>
								<input class="form-control" label="Data" type="date" v-model="filterItems.start_date"/>
								<div class="invalid-feedback d-block" v-if="errors.start_date">
									<p>{{ errors.start_date[0] }}</p>
								</div>
							</div>
						</div>
						<div class="col-md-3 mt-2">
							<div class="form-group">
								<label class="form-control-label" for="input-end_date">Data Final</label>
								<input class="form-control" label="Data" type="date" v-model="filterItems.end_date"/>
								<div class="invalid-feedback d-block" v-if="errors.end_date">
									<p>{{ errors.end_date[0] }}</p>
								</div>
							</div>
						</div>
						
						<div class="col-md-12 mt-2 text-right">
							<button class="btn btn-success" @click="generateReport()">GERAR RELATÓRIO</button>
						</div>

					</div>
				</div>
			</div>
		</div>
		<!-- end filter -->

	</div>
</template>

<script>
	import ApiService from '../../../../apiservice.js';
	export default {
		mixins: [ApiService],
		data() {
			return {
				companies: [],
				filterCompanies: [],
				filterListItems: [],
				filterWorkplaces: [],
				filterSublocal: [],
				filterContracts: [],
				filterSectors: [],
				filterSubsectors: [],
        filterTemplates: [],
				modalSectors: [],
				modalSubsectors: [],
				gridSectors: [],
				gridSubsectors: [],
				gridItems: {},
				workplaces: [],
				colaborators: [],
				filterItems: {
					type: 'last'
				},
				items: [],
				itemsSelected: [],
				tempItems: [],
				sectors: [],
				subsectors: [],
				gridSelected: [],
				iteminventoriesTitle: "Nova Movimentação de Inventário",
				iteminventory: {
					status: null,
					company_id: null,
					contract_id: null,
					work_place_id: null,
					sublocal_id: null,
					colaborator_id: null,
					sector_id: null,
					subsector_id: null,
          movement_id: null
				},
				isEditItemInventories: false,
				iteminventoriesID: "",
				iteminventoriesItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				modalMove: {
					itemsSelected: []
				},
				optionsStatus: [{
					label: 'Ativo',
					value: 1
				},{
					label: 'Pausado',
					value: 2
				}],
				optionsType: [{
					label: 'Última Movimentação',
					value: 'last'
				},{
					label: 'Todas Movimentações',
					value: 'all'
				}],
				tempColaborators: [],
				tempSectors: [],
				tempSubsectors: [],
				tempWorkplaces: [],
				userCan: ''
			}
		},
		mounted() {
			this.loadCompanies();
      this.loadTemplates();
		},
		methods: {
			loadItems(){
				let _this = this;
				_this.loading = true;
				_this.items = [];
				_this.tempItems = [];
				_this.filterListItems = [];

				let dataFilter = {
					"company_id" : _this.filterItems.company_id,
					"contract_id" : _this.filterItems.contract_id,
					"work_place_id" : _this.filterItems.work_place_id,
					"sublocal_id" : _this.filterItems.sublocal_id,
					"sector_id" : _this.filterItems.sector_id,
					"subsector_id" : _this.filterItems.subsector_id
				};

				axios.post("/admin/items/select", dataFilter).then((res) => {
					_this.tempItems = _this.tempItems.concat(res.data.data);
					_this.filterListItems = _this.filterListItems.concat(res.data.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadContracts() {
				let _this = this;
				_this.loading = true;
				_this.filterContracts = [];
				axios.get("/admin/companycontracts/"+_this.filterItems.company_id+"/search").then((res) => {
					res.data.map((rowContract) => {
						_this.filterContracts.push({
							id: rowContract.contract_id,
							corporate_name: rowContract.contracts.corporate_name
						});
					});
					_this.filterContracts.sort(function(a, b){
						return a.corporate_name - b.corporate_name;
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadCompanies() {
				let _this = this;
				axios.get("/admin/companies").then((res) => {
					res.data.sort(function(a, b){
						return a.corporate_name - b.corporate_name;
					});
					_this.companies = _this.companies.concat(res.data);
					_this.filterCompanies = _this.filterCompanies.concat(res.data);
				}).catch((err) => {
				});
			},
			loadColaborators() {
				let _this = this;
				_this.colaborators = [];
				_this.loading = true;
				axios.get("/admin/companycolaborators/"+_this.filterItems.company_id+"/search").then((res) => {
					res.data.map((rowColaborator) => {
						_this.colaborators.push({
							label: rowColaborator.colaborators.users.profile.name,
							value: rowColaborator.colaborator_id
						});
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadSectors(source) {
				let _this = this;
				_this.loading = true;

				if(source == 'filter'){

					_this.filterSectors = [];
					_this.modalSectors = [];
					_this.gridSectors = [];

					axios.get("/admin/sectors/"+_this.filterItems.sublocal_id+"/search").then((res) => {
						res.data.sort(function(a, b){
							return a.name - b.name;
						});
						_this.filterSectors = _this.filterSectors.concat(res.data);
						_this.modalSectors = _this.modalSectors.concat(res.data);
						_this.gridSectors = _this.gridSectors.concat(res.data);
						_this.loading = false;
					}).catch((err) => {
						_this.loading = false;
				});

				}

			},
			loadSubsectors(source) {
				let _this = this;
				_this.loading = true;

				if(source == 'filter'){

					_this.filterSubsectors = [];
					_this.modalSubsectors = [];
					_this.gridSubsectors = [];
				
					axios.get("/admin/subsectors/"+_this.filterItems.sublocal_id+"/search").then((res) => {
						res.data.sort(function(a, b){
							return a.name - b.name;
						});
						_this.filterSubsectors = _this.filterSubsectors.concat(res.data);
						_this.modalSubsectors = _this.modalSubsectors.concat(res.data);
						_this.gridSubsectors = _this.gridSubsectors.concat(res.data);
						_this.loading = false;
					}).catch((err) => {
						_this.loading = false;
					});

				}

			},
      loadTemplates(){
        let _this = this;
				_this.loading = true;
				let dataFilter = {
					"type" : "inventory"
				};
				axios.post("/admin/templates/filter", dataFilter).then((res) => {

					_this.loading = false;

					_this.filterTemplates = [];
					_this.filterTemplates = res.data.data;

				}).catch((err) => {

					_this.loading = false;

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
      },  
      generateReport() {
				let _this = this;

        if(_this.filterItems.company_id && _this.filterItems.contract_id && _this.filterItems.work_place_id && _this.filterItems.sublocal_id && _this.filterItems.template_id){

          _this.loading = true;
          _this.errors = [];
          _this.iteminventoriesItems = [];

          let dataFilter = {
            "company" : _this.filterItems.company_id ?? null,
            "contract" : _this.filterItems.contract_id ?? null,
            "workplace" : _this.filterItems.work_place_id ?? null,
            "sublocal": _this.filterItems.sublocal_id ?? null,
            "items" : _this.filterItems.itemsSelected ?? null,
            "sector" : _this.filterItems.sector_id ?? null,
            "subsector" : _this.filterItems.subsector_id ?? null,
            "start_date" : _this.filterItems.start_date ?? null,
            "end_date" : _this.filterItems.end_date ?? null,
            "movement_id": _this.filterItems.movement_id ?? null,
            "template_id": _this.filterItems.template_id ?? null,
            "type" : _this.filterItems.type ?? null
          };

          //mount url
          var url = "/admin/reports/inventories/excel/full?filters="+JSON.stringify(dataFilter);

          window.open(url,'_blank');

          _this.loading = false;

          _this.$swal({
            title: "Sucesso",
            text: "Relatório gerado com Sucesso",
            icon: "success"
          });

        }
				
			},
			validateCompany(source){
				let _this = this;
				_this.loading = true;

				if(source == 'filter'){

					_this.filterWorkplaces = [];
					axios.get("/admin/companyworkplaces/"+_this.filterItems.company_id+'/search').then((res) => {
						res.data.map((rowReturn) => {
							_this.filterWorkplaces = _this.filterWorkplaces.concat(rowReturn.work_places);
						});
						_this.filterWorkplaces.sort(function(a, b){
							return a.corporate_name - b.corporate_name;
						});
						_this.loadContracts();
					}).catch((err) => {
					});
					this.loading = false;

				}
			},
			validateWorkplace(source){
				let _this = this;

				if(source == 'filter'){

					_this.filterSublocal = [];
					axios.get("/admin/workplacesublocal/"+_this.filterItems.work_place_id+"/search").then((res) => {
						res.data.map((rowSublocal) => {
							_this.filterSublocal.push({
								corporate_name: rowSublocal.sublocal.corporate_name,
								id: rowSublocal.sublocal_id
							});
						});
						_this.loading = false;
					}).catch((err) => {
						_this.loading = false;
					});

				}

			},
			validateSublocal(source){
				let _this = this;

				_this.loadSectors(source);
				_this.loadSubsectors(source);
				_this.loadItems();

			}
		}
	}
</script>
