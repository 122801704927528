<template>
	<div class="row">
		<div class="col-12">
			<div class="card">

				<!-- card header -->
				<div class="card-header">
					<h4>Confira abaixo todas as informações da Ocorrência</h4>
				</div>
				<!-- end card header -->

				<!-- card body -->
				<div class="card-body">

					<!-- form -->
					<h6 class="heading-small text-muted mb-4">Dados</h6>
					<div class="">
						<div class="row">

							<!-- title -->
							<div class="col-md-8">
								<div class="form-group">
									<label class="form-control-label" for="input-title">Título</label>
									<input type="text" id="input-title" v-bind:class="{'is-invalid': errors.title}"  class="form-control" placeholder="Título" v-model="title" required="false" :disabled="true">
									<div class="invalid-feedback d-block" v-if="errors.title">
										<p>{{ errors.title[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end title -->

							<!-- activity_status_id -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-activity_status_id">Status</label>
									<v-select :options="activitystatus" label="name" v-bind:class="{'is-invalid': errors.activity_status_id}" :reduce="name => name.id" v-model="activity_status_id" required :disabled="true"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.activity_status_id">
										<p>{{ errors.activity_status_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end activity_status_id -->

							<!-- description -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-description">Descrição</label>
									<textarea type="text" id="input-description" v-bind:class="{'is-invalid': errors.description}" class="form-control" placeholder="Descrição" v-model="description" rows="8" required="false" :disabled="true"></textarea>
									<div class="invalid-feedback d-block" v-if="errors.description">
										<p>{{ errors.description[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end description -->

							<!-- details -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-details">Detalhes</label>
									<textarea type="text" id="input-details" v-bind:class="{'is-invalid': errors.details}" class="form-control" placeholder="Detalhes" v-model="details" rows="8" required="true" :disabled="true"></textarea>
									<div class="invalid-feedback d-block" v-if="errors.details">
										<p>{{ errors.details[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end details -->

							<!-- expected_date -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-expected_date">Data de Abertura</label>
									<input type="date" id="input-expected_date" v-bind:class="{'is-invalid': errors.expected_date}"   class="form-control" placeholder="Data prevista" v-model="expected_date" required="false" :disabled="true">
									<div class="invalid-feedback d-block" v-if="errors.expected_date">
										<p>{{ errors.expected_date[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end expected_date -->

							<!-- expected_time -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-expected_time">Hora de Abertura</label>
									<input type="time" id="input-expected_time" v-bind:class="{'is-invalid': errors.expected_time}"   class="form-control" placeholder="Hora Prevista" v-model="expected_time" required="false" :disabled="true">
									<div class="invalid-feedback d-block" v-if="errors.expected_time">
										<p>{{ errors.expected_time[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end expected_time -->

							<!-- time -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-time">Tempo</label>
									<input type="tel" id="input-time" v-bind:class="{'is-invalid': errors.time}"   class="form-control" placeholder="Tempo" v-model="time" required="false"  :disabled="true">
									<div class="invalid-feedback d-block" v-if="errors.time">
										<p>{{ errors.time[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end time -->

							<!-- duration -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-duration">Duração</label>
									<input type="time" id="input-duration" v-bind:class="{'is-invalid': errors.duration}"   class="form-control" placeholder="Duração" v-model="duration" required="false" :disabled="true">
									<div class="invalid-feedback d-block" v-if="errors.duration">
										<p>{{ errors.duration[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end duration -->

							<!-- template_id -->
							<div class="col-md-8">
								<div class="form-group">
									<label class="form-control-label" for="input-template_id">Template</label>
									<v-select :options="templates" label="name" v-bind:class="{'is-invalid': errors.template_id}" :reduce="name => name.id" v-model="template_id" @input="validateDelay()" required :disabled="true"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.template_id">
										<p>{{ errors.template_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end template_id -->

							<!-- allow delay -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-allow_delay">Permitir Atraso?</label>
									<v-select :options="allowDelays" v-bind:class="{'is-invalid': errors.allow_delay}" v-model="allow_delay" required :disabled="true"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.allow_delay">
										<p>{{ errors.allow_delay[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end allow delay -->

							<!-- companY -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-work_place_id">Empresa</label>
									<v-select :options="companies" label="corporate_name" v-bind:class="{'is-invalid': errors.company_id}" :reduce="corporate_name => corporate_name.id" v-model="company_id" required @input="validateRelationships()" :disabled="true"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.company_id">
										<p>{{ errors.company_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end company -->

							<!-- work_place_id -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-work_place_id">Sublocal de Trabalho</label>
									<v-select :options="workplaces" label="corporate_name" v-bind:class="{'is-invalid': errors.work_place_id}" :reduce="corporate_name => corporate_name.id" v-model="work_place_id" required @input="validateWorkplace()" :disabled="true"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.work_place_id">
										<p>{{ errors.work_place_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end work_place_id -->

							<!-- colaborator_id -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-colaborator_id">Colaborador</label>
									<v-select :options="colaborators" label="name" v-bind:class="{'is-invalid': errors.colaborator_id}" :reduce="name => name.colaborator_id" v-model="colaborator_id" required :disabled="true"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.colaborator_id">
										<p>{{ errors.colaborator_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end colaborator_id -->

              <!-- item_id -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-item_id">Item</label>
									<v-select :options="items" label="name" v-bind:class="{'is-invalid': errors.item_id}" :reduce="name => name.id" v-model="item_id" required :disabled="true"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.item_id">
										<p>{{ errors.item_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end item_id -->

							<!-- sector_id -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-sector_id">Setor</label>
									<v-select :options="sectors" label="name" v-bind:class="{'is-invalid': errors.sector_id}" :reduce="name => name.id" v-model="sector_id" :disabled="true"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.sector_id">
										<p>{{ errors.sector_id[0] }}</p>
									</div>
								</div>
							</div>
							
							<!-- subsector_id -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-subsector_id">Subsetor</label>
									<v-select :options="subsectors" v-bind:class="{'is-invalid': errors.subsector_id}" :reduce="name => name.id" label="name" v-model="subsector_id" :disabled="true"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.subsector_id">
										<p>{{ errors.subsector_id[0] }}</p>
									</div>
								</div>
							</div>


							<!-- file -->
							<!-- <div class="col-md-10">
								<div class="form-group">
									<label class="form-control-label" for="input-file">Arquivo</label>
									<div class="custom-file">
										<input type="file" class="custom-file-input" v-bind:class="{'is-invalid': errors.file}" id="file" name="file" ref="file" lang="pt" v-on:change="handleFileUpload()" :disabled="true">
										<label class="custom-file-label" for="file">{{fileName}}</label>
									</div>
									<div class="invalid-feedback d-block" v-if="errors.file">
										<p>{{ errors.file[0] }}</p>
									</div>
								</div>
							</div>

							<div class="col-md-2">
								<div class="form-group">
									<a href="#" class="btn btn-outline-primary btn-view-file" @click="viewFile()">
										<i class="fas fa-eye"></i>
										Visualizar Arquivo
									</a>
								</div>
							</div> -->
							<!-- end file -->

						</div>
					</div>
					<!-- end form -->

				</div><!-- card body -->
			</div><!-- card -->

			<div class="card">
				<div class="card-header">
					<h4>Informações de Preenchimento da Ocorrência</h4>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">
							<label>Executado por</label>
							<input class="form-control" v-model="doneInfo.executed_by" :disabled="true"/>
						</div>

						<div class="col-md-12 mt-4">
							<label>Mensagem</label>
							<textarea class="form-control" v-model="doneInfo.infos" style="height: 120px;" rows="4" :disabled="true"></textarea>
						</div>

						<div class="col-md-6 mt-4">
							<label>Imagem Antes</label>
							<img class="preview-image" :src="doneInfo.imageBefore" v-if="doneInfo.imageBefore !== ''"/>
							<span class="empty-image" v-else>Imagem Antes</span>
						</div>

						<div class="col-md-6 mt-4">
							<label>Imagem Depois</label>
							<img class="preview-image" :src="doneInfo.imageAfter" v-if="doneInfo.imageAfter !== ''"/>
							<span class="empty-image" v-else>Imagem Depois</span>
						</div>
					</div>
				</div>
			</div>
		</div><!-- col-12 -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- row -->
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
    props: ["occurrences"],
		mixins: [ApiService],
		data() {
			return {
				activitystatus: [],
				activity_status_id: this.getData("incident_status_id"),
				allowDelays: [],
				allow_delay: '',
				allowDelayDisabled: false,
				title: this.getData("title"),
				companies: [],
				company_id: this.getData("company_id"),
				description: this.getData("description"),
				details: this.getData("details"),
				doneInfo: {
					infos: '',
					imageBefore: this.validateImage(),
					imageAfter: '',
				},
				expected_date: this.getData("expected_date"),
				expected_time: this.getData("expected_time"),
				file: '',
				fileName: '',
				time: "",
				duration: "",
				item_id: this.getData("item_id"),
				items: [],
				sectors: [],
				sector_id: this.getData("activity").sector_id,
				subsectors: [],
				subsector_id: this.getData("activity").subsector_id,
				work_place_id: this.getData("work_place_id"),
				workplaces: [],
				template_id: 6,
				templates: [],
				colaborator_id: this.getData("colaborator_id"),
				colaborators: [],
				colaboratorgroups: [],
				tempColaborators: [],
				tempColaboratorsGroups: [],
				errors: [],
				message: "",
				loading: false,
				fullPage: true
			}
		},
		mounted() {
			this.loadItems();
			this.loadCompanies();
			this.loadTemplates();
			this.loadActivityStatus();
			this.validateDone();
		},
		methods: {
      getData(key) {
				return JSON.parse(this.occurrences)[key];
			},
			handleFileUpload(){
				this.file = this.$refs.file.files[0];
				this.fileName = this.$refs.file.files[0].name;
			},
			newData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;

				let formData = new FormData();
				formData.append("title", _this.title);
				formData.append("description", _this.description);
				formData.append("details", _this.details);
				formData.append("expected_date", _this.expected_date);
				formData.append("expected_time", _this.expected_time);
				formData.append("time", _this.time);
				formData.append("duration", _this.duration);
				formData.append("item_id", _this.item_id);
				formData.append("work_place_id", _this.work_place_id);
				formData.append("template_id", _this.template_id);
				formData.append("colaborator_id", _this.colaborator_id);
				formData.append("activity_status_id", _this.activity_status_id);
				formData.append("allow_delay", _this.allow_delay);
				formData.append("company_id", _this.company_id);
				formData.append("file", _this.file);

				axios.post("/admin/occurrences", formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

					_this.loading = false;
					_this.resetForm();

					//call function in ApiService to validate success
					_this._api_validateSuccess(res,true);

				}).catch((err) => {

					_this.loading = false;

					if (err.response.data.errors){
						//set errors
          	_this.errors = err.response.data.errors;
					}

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			},
			resetForm() {
				this.title = '';
				this.description = '';
				this.details = '';
				this.expected_date = '';
				this.expected_time = '';
				this.time = '';
				this.duration = '';
				this.item_id = '';
				this.work_place_id = '';
				this.template_id = '';
				this.colaborator_id = '';
				this.file = '';
				this.fileName = '';
			},
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/items").then((res) => {
					_this.items = _this.items.concat(res.data);
					_this.loading = false;
					_this.loadSectors();
					_this.loadSubsectors();
					_this.selectItem();
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadCompanies() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/companies").then((res) => {
					_this.companies = _this.companies.concat(res.data);
          _this.loadWorkPlaces();
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadActivityStatus() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/incidentstatus").then((res) => {
					_this.activitystatus = _this.activitystatus.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadSectors() {
				let _this = this;
				_this.loading = true;

				_this.sectors = [];

				axios.get("/admin/sectors/"+_this.work_place_id+"/search").then((res) => {
					_this.sectors = _this.sectors.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});

			},
			loadSubsectors() {
				let _this = this;
				_this.loading = true;

				_this.subsectors = [];
			
				axios.get("/admin/subsectors/"+_this.work_place_id+"/search").then((res) => {
					_this.subsectors = _this.subsectors.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});

			},
			loadWorkPlaces() {
				let _this = this;
				_this.loading = true;
				_this.workplaces = [];
				axios.get("/admin/sublocal/"+_this.work_place_id+"/info").then((res) => {
					_this.workplaces.push(res.data);
          _this.loadColaborators();
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadTemplates() {
				let _this = this;
				_this.templates = [];
				_this.loading = true;
				axios.get("/admin/templates").then((res) => {
					_this.templates = _this.templates.concat(res.data);
					_this.loading = false;
          _this.validateDelay();
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadColaborators() {
				let _this = this;
				_this.colaborators = [];
				_this.tempColaborators = [];
				_this.loading = true;
				axios.get("/admin/companycolaborators/"+_this.company_id+"/search").then((res) => {
					res.data.map((row) => {
						row.name = row.colaborators.users.profile.name;
						_this.colaborators.push(row);
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			selectItem(){
				this.items.map((rowTempItem) => {
					if(rowTempItem.id == this.item_id){
						this.sector_id = rowTempItem.inventory.sector_id;
						this.subsector_id = rowTempItem.inventory.subsector_id;
					}
				});
			},
			validateDelay(){
				let _this = this;
				_this.allowDelays = [];
				
				//read all templates to get info of the selected
				_this.templates.map((itemTemplate) => {
					if(itemTemplate.id == _this.template_id){
						switch (itemTemplate.frequencies.type) {
							case 'day':
								if (itemTemplate.frequencies.time == 1 || itemTemplate.frequencies.time == 0){
									_this.allowDelayDisabled = true;
								} else {
									for(var i = 1; i < itemTemplate.frequencies.time; i++){
										_this.allowDelays.push(i);
									}
								}								
								break;

							case 'month':
								for(var i = 1; i <= 31; i++){
									_this.allowDelays.push(i);
								}
								break;

							case 'year':
								for(var i = 1; i <= 12; i++){
									_this.allowDelays.push(i);
								}
								break;
						
							default:
								_this.allowDelayDisabled = true;
								break;
						}

					}
				});
			},
			validateDone(){

				//validate activity
				var activity = this.getData('activity');

				if(activity !== null && activity !== undefined){

					if(activity.templates.checklists[0].answer){

						this.doneInfo.infos = activity.templates.checklists[0].answer.answer;

						if(activity.templates.checklists[0].answer.files.length > 0){
							this.doneInfo.imageAfter = "/storage/answers/"+activity.templates.checklists[0].answer.files[0].file;
						}

					}

				}
				//end validate activity

				//validate executed by
				var executed = this.getData('executed_info');
				if(executed !== null && executed !== undefined){

					this.doneInfo.executed_by = executed.users.profile.name;

				}
				//end validate executed by

			},
			validateImage(){
				var files = this.getData('files');
				if(files.length > 0){
					return '/storage/occurrences/'+files[0].file;
				}
			},
			validateRelationships(){
				this.loadColaborators();
				this.loadWorkPlaces();
			},
			validateWorkplace(){
				let _this = this;
				_this.loading = true;
				axios.get("/admin/companyworkplacecolaborators/"+_this.company_id+"/search").then((res) => {
					
					res.data.map((itemWorkplaceColaborator) => {

						if(itemWorkplaceColaborator.work_place_id == _this.work_place_id){

							//read all colaborators
							_this.tempColaborators.map((itemColaborator) => {
								if(itemColaborator.colaborator_id == itemWorkplaceColaborator.colaborator_id){
									if(!_this.colaborators.some(data => data.id === itemColaborator.id)){
										_this.colaborators.push(itemColaborator);   
									}
								}
							});

						}
					});

					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			viewFile(){

				var occurrenceInfo = JSON.parse(this.occurrences);
			
				window.open("/storage/occurrences/"+occurrenceInfo.files[0].file,"_blank");

			}
		}
	}
</script>
