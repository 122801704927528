<template>
	<div class="tab-pane fade" id="tab-sectors" role="tabpanel" aria-labelledby="sectors-tab">

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Setores cadastrados</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalSectors"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="sectorsItems.length"
						:columns="tableColumns"
						:rows="sectorsItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum setores cadastrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalSectors" tabindex="-1" aria-labelledby="modalSectorsLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalSectorsLabel">{{sectorsTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">
									
									<!-- name -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-name">Nome</label>
											<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}" maxlength="60" class="form-control" placeholder="Nome" v-model="sector.name" required="false" >
											<div class="invalid-feedback d-block" v-if="errors.name">
												<p>{{ errors.name[0] }}</p>
											</div>
										</div>
									</div>

									<!-- circuit -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-circuit">Circuito</label>
											<v-select :options="circuits" label="name" v-bind:class="{'is-invalid': errors.circuit}" :reduce="name => name.id" v-model="sector.circuit"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.circuit">
												<p>{{ errors.circuit[0] }}</p>
											</div>
										</div>
									</div>

									<!-- route -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-route">Rota</label>
											<v-select :options="routes" label="name" v-bind:class="{'is-invalid': errors.route}" :reduce="name => name.id" v-model="sector.route"></v-select>
											<div class="invalid-feedback d-block" v-if="errors.route">
												<p>{{ errors.route[0] }}</p>
											</div>
										</div>
									</div>

									<!-- directors -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-directors">Diretoria</label>
											<input type="text" id="input-directors" v-bind:class="{'is-invalid': errors.directors}" maxlength="60" class="form-control" placeholder="" v-model="sector.directors">
											<div class="invalid-feedback d-block" v-if="errors.directors">
												<p>{{ errors.directors[0] }}</p>
											</div>
										</div>
									</div>

									<!-- management -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-management">Gerência</label>
											<input type="text" id="input-management" v-bind:class="{'is-invalid': errors.management}" maxlength="60" class="form-control" placeholder="" v-model="sector.management">
											<div class="invalid-feedback d-block" v-if="errors.management">
												<p>{{ errors.management[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- description -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-description">Descrição</label>
											<textarea type="text" id="input-description" v-bind:class="{'is-invalid': errors.description}" maxlength="250" class="form-control" placeholder="Descrição" v-model="sector.description" rows="8"></textarea>
											<div class="invalid-feedback d-block" v-if="errors.description">
												<p>{{ errors.description[0] }}</p>
											</div>
										</div>
									</div>

								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions','workplaces'],
		data() {
			return {
				circuits: [],
				routes: [],
				workPlaceID: '',
				sectorsTitle: "Novo Setor",
				sector: {
					name: '',
					description: '',
					circuit: '',
					route: '',
					director: '',
					management: ''
				},
				isEditSectors: false,
				sectorsID: "",
				sectorsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'ID',
						field: 'id',
					},{
						label: 'Nome',
						field: 'name',
					},{
						label: 'Circuito',
						field: 'circuit.name',
					},{
						label: 'Rota',
						field: 'route.name',
					},{
						label: 'Diretoria',
						field: 'directors',
					},{
						label: 'Gerência',
						field: 'management',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.workPlaceID = (JSON.parse(this.workplaces)).id;
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
			this.loadCircuits();
			this.loadRoutes();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/sectors/"+_this.workPlaceID+"/search").then((res) => {
					_this.sectorsItems = _this.sectorsItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadCircuits() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/circuits").then((res) => {
					_this.circuits = _this.circuits.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadRoutes() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/routes").then((res) => {
					_this.routes = _this.routes.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditSectors == true){

					let dataInfo = {
						"name" : _this.sector.name,
						"description" : _this.sector.description,
						"circuit" : _this.sector.circuit,
						"route" : _this.sector.route,
						"directors" : _this.sector.directors,
						"management" : _this.sector.management,
						"workplace": _this.workPlaceID,
						"_method": "PATCH"
					};
					axios.post("/admin/sectors/"+_this.sectorsID, dataInfo).then((res) => {

						_this.sectorsItems.map((rowSector) => {
							if(rowSector.id == _this.sectorsID){
								rowSector.route = res.data.data.route;
								rowSector.circuit = res.data.data.circuit;
								rowSector.management = res.data.data.management;
								rowSector.directors = res.data.data.directors;
							}
						});

						_this.loading = false;
						_this.resetForm();

						$('#modalSectors').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"name" : _this.sector.name,
						"description" : _this.sector.description,
						"circuit" : _this.sector.circuit,
						"route" : _this.sector.route,
						"directors" : _this.sector.directors,
						"management" : _this.sector.management,
						"workplace": _this.workPlaceID,
						"last": ""
					};
					axios.post("/admin/sectors", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.sectorsItems.push(res.data.data);
						$('#modalSectors').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Setor",
					text: "Tem certeza que deseja excluir esse Setor?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/sectors/"+ID).then((res) => {

							_this.sectorsItems.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,false);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.sector.name = item.name;
				this.sector.description = item.description;
				this.sector.circuit = item.circuit_id;
				this.sector.route = item.route_id;
				this.sector.director = item.director;
				this.sector.management = item.management;

				this.isEditSectors = true;
				this.sectorsID = item.id;
				this.sectorsTitle = "Editar Setor";

				$("#modalSectors").modal('show');

			},
			resetForm(){

				this.sector = {
					name: '',
					description: '',
				};
				this.errors = [];

				this.isEditSectors = false;
				this.sectorsID = "";
				this.sectorsTitle = "Novo Setor";

			}
		}
	}
</script>
