<template>
	<div class="tab-pane fade show active" id="tab-geral" role="tabpanel" aria-labelledby="geral-tab">
		<div class="row">
			<div class="col-12">
				<div class="card">

					<!-- card header -->
					<!-- card header -->
					<div class="card-header">
						<h4>Preencha os dados abaixo</h4>

						<a @click="updateData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
					</div>
					<!-- end card header -->

					<!-- card body -->
					<div class="card-body">

						<!-- form -->
						<h6 class="heading-small text-muted mb-4">Dados</h6>
						<div class="">
							<div class="row">

								<!-- name -->
								<div class="col-md-12">
									<div class="form-group">
										<label class="form-control-label" for="input-name">Nome</label>
										<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}" maxlength="60" class="form-control" placeholder="Nome" v-model="name" required="false" >
										<div class="invalid-feedback d-block" v-if="errors.name">
											<p>{{ errors.name[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-12 -->
								<!-- end name -->

								<!-- type -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-type">Tipo</label>
										<v-select :options="optionsType" v-bind:class="{'is-invalid': errors.type}" :reduce="label => label.value" v-model="type"></v-select>
										<div class="invalid-feedback d-block" v-if="errors.type">
											<p>{{ errors.type[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-6 -->
								<!-- end type -->

								<!-- frequency_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-frequency_id">Periodicidade</label>
										<v-select :options="frequencies" label="name" v-bind:class="{'is-invalid': errors.frequency_id}" :reduce="name => name.id" v-model="frequency_id"></v-select>
										<div class="invalid-feedback d-block" v-if="errors.frequency_id">
											<p>{{ errors.frequency_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-6 -->
								<!-- end frequency_id -->

								<!-- description -->
								<div class="col-md-12">
									<div class="form-group">
										<label class="form-control-label" for="input-description">Descrição</label>
										<textarea type="text" id="input-description" v-bind:class="{'is-invalid': errors.description}" class="form-control" maxlength="250" placeholder="Descrição" v-model="description" rows="8" required="false"></textarea>
										<div class="invalid-feedback d-block" v-if="errors.description">
											<p>{{ errors.description[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-12 -->
								<!-- end description -->

							</div>
						</div>
						<!-- end form -->

					</div><!-- card body -->
				</div><!-- card -->
			</div><!-- col-12 -->

			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div><!-- row -->
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		props: ["templates"],
		mixins: [ApiService],
		data() {
			return {
				name: this.getData("name"),
				description: this.getData("description"),
				type: this.getData("type"),
				frequency_id: this.getData("frequency_id"),
				frequencies: [],
				errors: [],
				message: "",
				optionsType: [{
					label: 'Consumo',
					value: 'consumption'
				},{
					label: 'Checklist',
					value: 'checklist'
				},{
					label: 'Romaneio',
					value: 'inventory'
				}],
				loading: false,
				fullPage: true
			}
		},
		mounted() {
			this.loadFrequencies();
		},
		methods: {
			getData(key) {
				return JSON.parse(this.templates)[key];
			},
			updateData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"name" : _this.name,
					"description" : _this.description,
					"type" : _this.type,
					"frequency_id" : _this.frequency_id,
					"_method": "PATCH"
				};
				axios.post("/admin/templates/"+this.getData('id'), dataInfo).then((res) => {

					_this.loading = false;

					//call function in ApiService to validate success
					_this._api_validateSuccess(res,true);

				}).catch((err) => {

					_this.loading = false;

					if (err.response.data.errors){
						//set errors
          	_this.errors = err.response.data.errors;
					}
					
				});
			},
			loadFrequencies() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/frequencies").then((res) => {
					_this.frequencies = _this.frequencies.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			}
		}
	}
</script>
